.service-total-row-even {
    background-color: #f2f2f24d;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    overflow: hidden;
}

.service-total-row-odd {
    background-color: #f6f6f6;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    overflow: hidden;
}

.service-total-row-odd:hover {
    background-color: #f6f6f6;
}

.service-total-row-even:hover {
    background-color: #f6f6f6;
}


.service-row-even {
    background-color: #f2f2f24d;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    overflow: hidden;
}

.service-row-odd {
    background-color: #f6f6f6;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    overflow: hidden;
}

.service-row-odd:hover {
    background-color: #f6f6f6;
}

.service-row-even:hover {
    background-color: #f6f6f6;
}

.service-row-even>td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.service-row-odd>td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.activity-row {
    background-color: #f6f6f6;
    font-size: 12px;
    font-family: 'TT Norms Pro';
}

.activity-row:nth-child(even) {
    background-color: #f2f2f24d;
}
  
.activity-row:nth-child(odd){background-color: #f2f2f2ab;}

.ant-table-thead>tr>th {
    /* background-color: #B0D2D8 !important; */
    background-color: #e1edf1 !important;
    color: #00518c !important;
    font-size: 12px;
    font-family: 'TT Norms Pro';
    text-transform: uppercase;
    font-weight: normal !important;
    padding: 6px 7px 4px 7px !important;
}